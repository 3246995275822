import { initializeApp } from "firebase/app";
import {
    getAuth,
    browserLocalPersistence,
    setPersistence,
    signInAnonymously,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

initializeApp(firebaseConfig);

export const auth = getAuth();

export const firestore = getFirestore();

export const loginAnonymously = async () => {
    await setPersistence(auth, browserLocalPersistence);
    return signInAnonymously(auth);
};
